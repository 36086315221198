import React, { useEffect, useState } from 'react'
import dynamic from 'next/dynamic'

import usePLPAnalytics from '@/hooks/usePLPAnalytics'
import { useClientSideCartAndUser } from '@/services/getSiteWideInitialProps'
import { debug, error, log } from '@/services/Log'
import type { NextPage } from 'next'
import type { FacetsType } from '@/types/ElasticSearch/SearchAPIResponse'
import type { QueryParamsType } from '@/types'
import type { SiteWideSSRProps } from '@/services/getSiteWideInitialProps'
import type { MinPLPProductInfo } from '@/types/PLPProducts'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { PLP_Items_Per_Page } from '@/data/constants/PLP_Items_Per_Page'
import { getLongformProseContent } from '@/microFrontends/seoFooter/client'
import type { LongformProseContent } from '@/microFrontends/seoFooter/types'
import { timePromiseAndLog } from '@/helpers/timePromiseAndLog'
import { getServerSideAbExperiment, useServerSideAbExperiment } from '@/helpers/getServerSideAbExperiment'
import { OPENSERARCH_EXPERIMENT_ID } from '@/services/Configuration'

const Default = dynamic(import('@/layouts/default'))
const PLP = dynamic(import('@/components/Plp/PLP'))

interface SearchPageProps extends SiteWideSSRProps {
  QueryParam: QueryParamsType,
  path: string,
  currentPage: number,
  longformProse: LongformProseContent | null,
  total: number | undefined,
  products: readonly MinPLPProductInfo[] | undefined,
  facets: FacetsType[] | undefined,
  itemsPerPage: number | undefined,
  userAgent: string,
  asPath: string,
  sort?: string
  shouldTrackOnBloomreach: boolean
  serverSideOpenSearchAB?: boolean
}

const SearchPage: NextPage<SearchPageProps> = ({
  QueryParam,
  currentPage,
  longformProse,
  sitewideBanner,
  megaNav,
  itemsPerPage,
  userAgent,
  products,
  facets,
  total,
  sort,
  eventBanner,
  footer,
  shouldTrackOnBloomreach,
  serverSideOpenSearchAB,
}) => {
  useServerSideAbExperiment({
    ctx: undefined,
    cookieName: 'openSearchAB',
    cookieFeature: 'isOpenSearchABEnabled',
    experimentId: OPENSERARCH_EXPERIMENT_ID,
    serverSideValue: serverSideOpenSearchAB,
  })
  const [isLoading, setIsLoading] = useState(false)
  const {
    cart, setCart, user, reloadCustomer,
  } = useClientSideCartAndUser()
  const title = QueryParam.query || ''

  const { PLPAnalytics } = usePLPAnalytics({
    plpType: 'search',
    cart,
    customer: user,
    products,
    categoryName: title,
  })

  useEffect(() => {
    PLPAnalytics()
  }, [products, cart, PLPAnalytics])

  const metaTags = { description: `Shop One Kings Lane ${title} | Luxurious and Stylish Home Furnishings`, title }
  const fallBackQuery = (
    (
      typeof window === 'object'
      && window.location.search.split('?')?.[1]?.split('&').find((q) => q.startsWith('query'))
    ) || `query=${QueryParam?.query || ''}`
  )
  const query = QueryParam.query ? `query=${QueryParam.query}` : fallBackQuery
  const baseHref = `/search.php?${query}&`
  const baseUrl = baseHref
  useEffect(() => {
    try {
      if (!products) {
        return
      }
      const content_ids = products.map(({ sku }) => sku)
      const contents = products.map(({ sku }) => ({ id: sku, quantity: 1, item_price: null }))
      allPromisesWithRetries(() => [import('@/services/Facebook')])
        .then(([{ Facebook }]) => Facebook.search({
          search_string: QueryParam?.query || '',
          content_ids,
          contents,
        }))
        .catch(error)
    } catch (err) {
      error('Facebook Pixel Error Failed to send Search event', err)
    }
  }, [QueryParam?.query, products, query, user])

  return (
    <Default
      shouldUsePageView={false}
      reloadCustomer={reloadCustomer}
      user={user}
      sitewideBanner={sitewideBanner}
      megaNav={megaNav}
      meta={metaTags}
      cart={cart}
      onUpdateCart={setCart}
      eventBanner={eventBanner}
      maybeFooterFromContentful={footer}
    >
      <PLP
        user={user}
        reloadCustomer={reloadCustomer}
        itemsPerPage={itemsPerPage}
        baseHref={baseHref}
        baseUrl={baseUrl}
        currentPage={currentPage}
        products={products}
        showBreadcrumbs={false}
        PLPHeader={title}
        totalNumberOfProducts={total}
        facets={facets}
        queryParam={QueryParam}
        userAgent={userAgent}
        isCategoryEvent={false}
        isLoading={isLoading}
        setIsLoading={setIsLoading}
        sortValue={sort}
        longformProse={longformProse}
        shouldTrackOnBloomreach={shouldTrackOnBloomreach}
        plpSource="search"
        plpSourceId={query}
      />

    </Default>
  )
}

SearchPage.getInitialProps = async (ctx) => {
  const {
    req, res, query, asPath = '',
  } = ctx
  if (typeof process !== 'undefined' && !process.browser) {
    log(JSON.stringify({
      event: 'PageView',
      page: 'Search',
      details: {
        query,
        asPath,
      },
    }))
  }

  // This is a janky but expedient way to do this
  // short of totally redoing this file and all dependents around search
  // to stop using URL objects as a primitive
  // (which is correct way to do it, but would take a lot of time and testing)
  const pathReplaced = asPath.replace(/^(\/c-[a-z-]+)\//, '$1')
  // conditionally parse the hyphens into spaces if it is in path
  const coalescedQuery = query?.query || ''
  const stringQuery = typeof coalescedQuery === 'string' ? coalescedQuery : coalescedQuery.join(',')
  const pathOrQueryFormNormalized = decodeURIComponent(asPath.startsWith('/browse')
    ? stringQuery.replace(/-/g, ' ')
    : stringQuery)
  const appendFakeQueryParamSuffix = pathReplaced.includes('?') ? '&' : '?'
  const path = pathReplaced.startsWith('/browse')
    ? `${pathReplaced}${appendFakeQueryParamSuffix}query=${pathOrQueryFormNormalized}`
    : pathReplaced
  const currentPage = parseInt(`${String(query?.page) || ''}`) || 1
  log(`Category Page getServerSideProps ${JSON.stringify({ path, asPath })}`)

  const [
    { getWindowAgent },
    { getSiteWideInitialProps, SiteWideInitialPropsFailure },
    { getRefererString },
    { getBloomreachConfig },
    { getQueryXssEscaped },
    { fetchAndProcessSearchPageProducts },
  ] = await allPromisesWithRetries(() => [
    import('@/helpers/common'),
    import('@/services/getSiteWideInitialProps'),
    import('@/helpers/getRefererString'),
    import('@/helpers/getBloomreachConfig'),
    import('@/helpers/getQueryWithValidCharacters'),
    import('@/hooks/useSearchPageProducts'),
  ])
  const legalQuery = getQueryXssEscaped({
    ...query,
    query: pathOrQueryFormNormalized,
  })
  const userAgent = getWindowAgent(req) || ''

  try {
    const itemsPerPageFromQueryParam = query.limit
    const itemsPerPage = (
      (
        typeof itemsPerPageFromQueryParam === 'string'
          ? parseInt(itemsPerPageFromQueryParam)
          : 0
      ) || PLP_Items_Per_Page
    )
    log('query', legalQuery)

    const serverSideOpenSearchAB = getServerSideAbExperiment({
      ctx: { req, res },
      cookieName: 'openSearchAB',
      cookieFeature: 'isOpenSearchABEnabled',
      experimentId: OPENSERARCH_EXPERIMENT_ID,
    })
    const [
      siteWideInitialProps,
      longformProse,
      productsResponse,
    ] = await Promise.all([
      timePromiseAndLog('SearchPage.getInitialProps.getSiteWideInitialProps')(getSiteWideInitialProps(ctx, serverSideOpenSearchAB)),

      // make this query to the seo content chunks on the CDN
      timePromiseAndLog('SearchPage.getInitialProps.getLongformProseContent')(getLongformProseContent(legalQuery.query || '')),
      timePromiseAndLog('SearchPage.getInitialProps.fetchAndProcessSearchPageProducts')(fetchAndProcessSearchPageProducts({
        asPath: path,
        query: legalQuery,
        referer: getRefererString(req?.headers?.referer),

        bloomreachConfig: getBloomreachConfig(ctx, serverSideOpenSearchAB),
      })),
    ])

    const { products, facets, total } = productsResponse ?? {}
    debug('longformProse', longformProse)
    return {
      QueryParam: legalQuery,
      ...siteWideInitialProps,
      longformProse,
      facets,
      path,
      total,
      currentPage,
      itemsPerPage,
      userAgent,
      asPath,
      products,
      shouldTrackOnBloomreach: !asPath.includes('/browse'),
      serverSideOpenSearchAB,
    }
  } catch (e) {
    log('Cannot get initial props in category page, redirecting', e)
    res?.writeHead(302, {
      Location: '/',
      'cache-control': 'public, must-revalidate, proxy-revalidate, max-age=0, s-maxage=0',
    })
    res?.end()
    return {
      QueryParam: legalQuery,
      path,
      products: [],
      facets: [],
      total: 0,
      currentPage,
      itemsPerPage: PLP_Items_Per_Page,
      ...SiteWideInitialPropsFailure,
      userAgent,
      longformProse: null,
      asPath: '',
      shouldTrackOnBloomreach: false,
    }
  }
}

export default SearchPage
