import { useCallback } from 'react'
import { error } from '@/services/Log'
import type { GraphQlCustomer } from '@/helpers/graphql'
import type { StandardCart } from '@/types/ShopFront/CheckoutStandards'
import allPromisesWithRetries from '@/helpers/allPromisesWithRetries'
import { MinPLPProductInfo } from '@/types/PLPProducts'
import { GAPlpPageView } from '@/types/ThirdPartyIntegrations/GAEvents'

interface PLPAnalyticsProps {
  cart: StandardCart | null,
  customer: GraphQlCustomer | null,
  products: readonly MinPLPProductInfo[] | undefined,
  categoryName: string,
  plpType: string,
}

const kebabCase = (str: string) => (
  `${str || ''}`.replace(/([a-z])([A-Z])/g, '$1-$2') // get all lowercase letters that are near to uppercase ones
    .replace(/[\s_]+/g, '-') // replace all spaces and low dash
    .toLowerCase()
)

const usePLPAnalytics: (p: PLPAnalyticsProps) => {
  PLPAnalytics: () => void
} = ({
  cart,
  customer,
  products,
  categoryName,
  plpType = 'category',
}) => {
  const PLPAnalytics = useCallback(async () => {
    try {
      if (!products) {
        return
      }
      const [
        { getCustomerOrderCount },
      ] = await allPromisesWithRetries(() => [
        import('@/helpers/common'),
      ])
      const cartTotal = cart?.total.toFixed(2) || 0
      const customerId = customer?.entityId || 0
      const customerEmail = customer?.email || ''
      const impressions = products.map((product, index) => ({
        name: product.name,
        id: product.variants[0].sku,
        price: product.calculated_price,
        brand: product.brand,
        category: categoryName,
        variant: product.variants[0].id,
        list: kebabCase(categoryName),
        position: index + 1,
        reviewCount: product.reviewCount,
        reviewRating: product.reviewRating,
      }))
      const orderCount = await getCustomerOrderCount()
      const sendToGA = () => {
        const collectionView: GAPlpPageView['collectionView'] = {
          event: 'collectionView',
          ecommerce: {
            currencyCode: 'USD',
            impressions,
            customerEmail,
            customerId,
            visitorType: customerId ? 'Logged In' : 'Guest', // Guest OR Logged In
          },
          customerEmail,
          pageName: 'plp', // category page,(/c)
        }
        const customerEvent: GAPlpPageView['customer'] = {
          event: 'customer',
          visitorType: customerId ? 'Logged In' : 'Guest', // Guest OR Logged In
          customerId, // customer ID
          customerEmail, // Hashed customer email
          customerOrdersCount: orderCount, // Lifetime orders for customer
          customerTotalSpent: '', // Lifetime customer value for customer
          cartTotal, // should be the subtotal of the shopping cart
          pageType: plpType,
          // should be one of the following:
          // home|category|product|cart|searchresults|checkout|purchase|other|account
        }
        window?.dataLayer?.push?.(collectionView)
        window?.dataLayer?.push?.(customerEvent)
      }
      if (window.loadedAndInteractive) {
        sendToGA()
      } else {
        window.addEventListener('loadedAndInteractive', sendToGA, { once: true })
      }
    } catch (err) {
      error('PLPAnalytics Error', err)
    }
  }, [cart, customer, products, categoryName, plpType])
  return { PLPAnalytics }
}

export default usePLPAnalytics
